import './App.css';
import { useRef, useState } from 'react';
import { fileSize } from 'humanize-plus';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Input,
  Button,
  Container,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';

function App() {
  const [error, setError] = useState();
  const [file, setFile] = useState();
  const [size, setSize] = useState('original');
  const [url, setUrl] = useState();
  const [type, setType] = useState('file');
  const [previewData, setPreviewData] = useState();
  const [fetching, setFetching] = useState();
  const [result, setResult] = useState();
  const formRef = useRef();
  const onTypeRadioChange = (e) => {
    setType(e.target.value);
  };
  const onSizeRadioChange = (e) => {
    setSize(e.target.value);
  };
  const changeFile = (e) => {
    setFile(e.target.value);
  };
  const changeUrl = (e) => {
    setUrl(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setPreviewData();
    setError();
    setFetching(true);
    const formData = new FormData(e.currentTarget);
    try {
      const response = await fetch('/analyze', {
        body: formData,
        method: 'POST',
      });
      if (!response.ok) {
        const err = await response.json();
        setFetching(false);
        return setError(err.message);
      }
      const data = await response.json();
      setPreviewData(data);
    } catch (e) {
      setError(e.message);
    } finally {
      setFetching(false);
    }
  };

  const onUploadClick = async () => {
    setError();
    const formData = new FormData(formRef.current);
    setFetching(true);
    try {
      const response = await fetch('/upload', {
        body: formData,
        method: 'POST',
      });
      if (!response.ok) {
        const err = await response.json();
        setFetching(false);
        return setError(err.message);
      }
      const data = await response.json();
      setResult(data);
      setPreviewData();
    } catch (e) {
      setError(e.message);
    } finally {
      setFetching(false);
    }
  };

  return (
    <Container className="App">
      <h3 id="err">{error}</h3>
      <form onSubmit={onSubmit} ref={formRef}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel component="legend">Upload via:</FormLabel>
              <RadioGroup
                aria-label="type"
                name="type"
                value={type}
                onChange={onTypeRadioChange}
              >
                <FormControlLabel
                  value="file"
                  control={<Radio />}
                  label="File"
                />
                <FormControlLabel value="url" control={<Radio />} label="URL" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Input
              label="URL"
              variant="outlined"
              name="file"
              type="file"
              value={file}
              onChange={changeFile}
              className={clsx({ hidden: type !== 'file' })}
            />
            <TextField
              id="urlInput"
              label="URL"
              variant="outlined"
              value={url}
              name="url"
              onChange={changeUrl}
              className={clsx({ hidden: type !== 'url' })}
              placeholder="Paste URL here"
            />
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel component="legend">Size: </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="size"
                value={size}
                onChange={onSizeRadioChange}
              >
                <FormControlLabel
                  value="original"
                  control={<Radio />}
                  label="Original"
                />
                <FormControlLabel
                  value="2000"
                  control={<Radio />}
                  label="2000px"
                />
                <FormControlLabel
                  value="1000"
                  control={<Radio />}
                  label="1000px"
                />
                <FormControlLabel
                  value="600"
                  control={<Radio />}
                  label="600px"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              disabled={fetching}
              variant="contained"
              color="primary"
              type="submit"
            >
              Preview
            </Button>
            {previewData && (
              <Button
                disabled={fetching}
                variant="contained"
                color="primary"
                onClick={onUploadClick}
              >
                Upload
              </Button>
            )}
          </Grid>
        </Grid>
      </form>

      {result && (
        <div id="result">
          Uploaded image to&nbsp;
          <a href={result.filePath} target="_blank" rel="noreferrer">
            {result.filePath}
          </a>
        </div>
      )}
      {previewData && (
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <h5>Original</h5>
            <ul>
              <li>
                Height: <strong>{previewData.original.height}px</strong>
              </li>
              <li>
                Width: <strong>{previewData.original.width}px</strong>
              </li>
              <li>
                Size: <strong>{fileSize(previewData.original.size)}</strong>
              </li>
            </ul>
            <img src={previewData.original.path} alt="original" />
          </Grid>
          <Grid item>
            <h5>Converted</h5>
            <ul>
              <li>
                Height: <strong>{previewData.converted.height}px</strong>
              </li>
              <li>
                Width: <strong>{previewData.converted.width}px</strong>
              </li>
              <li>
                Size: <strong>{fileSize(previewData.converted.size)}</strong>
              </li>
            </ul>
            <img src={previewData.converted.path} alt="converted" />
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default App;
